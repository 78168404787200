import React from 'react';
import Layout from '../../components/layout';
import InfosTitle from '../../components/InfosPages/infosTitle';
import ReturnLink from '../../components/InfosPages/ReturnLink';

const infos = require('../../Politique.json');
const gray = '#FCFCFC';
const darkerGray = 'rgba(245, 245, 245, 1)';
const green = 'rgba(47, 137, 117, 1)';

const isLastRow = (group, item) => {
  return group.indexOf(item) === group.length || group.indexOf(item) === group.length - 1;
};

const Politique = () => {
  return (
    <Layout>
      <ReturnLink />
      <div className="MainWrapper / -lg:px-16 pb-72 flex flex-col items-start justify-start max-w-full px-3 mt-6">
        <h1 className=" my-5 text-3xl font-medium" style={{ color: green }}>
          Politique de confidentialité Check-RENO
        </h1>
        <hr className=" w-full h-px" style={{ background: '#AAC3C6' }} />
        <div className="flex-nowrap flex flex-col items-start w-2/3 px-3" style={{ background: gray }}>
          {infos.firstpart.map((info) => (
            <InfosTitle key={infos.firstpart.indexOf(info)} title={info.title} text={info.text} />
          ))}
          <h2 className="text-2xl">Ces fondements sont précisés ci-dessous :</h2>
          {infos.secondpart.items.map((info) => (
            <InfosTitle key={infos.secondpart.items.indexOf(info)} title={info.title} text={info.text} />
          ))}
          <div className="w-full p-3">
            <InfosTitle title={infos.thirdpart.maintitle} />
            <span>{infos.thirdpart.substring}</span>
            <div className="flex flex-col w-full p-5 mt-8" style={{ background: darkerGray }}>
              <div className="flex-nowrap flex flex-row w-full">
                {infos.thirdpart.table.columns.map((c) => (
                  <div
                    key={infos.thirdpart.table.columns.indexOf(c)}
                    className={`${
                      infos.thirdpart.table.columns.indexOf(c) + 1 === infos.thirdpart.table.columns.length
                        ? ''
                        : 'border-r-2'
                    } Data / w-1/2 py-6 text-center border-b-2 `}
                  >
                    <span style={{ color: green }}>{c.text}</span>
                  </div>
                ))}
              </div>
              {infos.thirdpart.table.datas.map((d) => (
                <div
                  className="flex-nowrap flex flex-row w-full"
                  key={infos.thirdpart.table.columns.indexOf(d)}
                >
                  <div
                    className={` ${
                      !isLastRow(infos.thirdpart.table.datas, d) ? 'border-b-2' : ''
                    } Data / w-1/2 p-5 text-left  border-r-2`}
                  >
                    <span className="" dangerouslySetInnerHTML={{ __html: d.left }} />
                  </div>
                  <div
                    className={` ${
                      !isLastRow(infos.thirdpart.table.datas, d) ? 'border-b-2' : ''
                    } Data / w-1/2 p-5 text-left `}
                  >
                    <span dangerouslySetInnerHTML={{ __html: d.right }} />
                  </div>
                </div>
              ))}
            </div>
            {infos.fourthpart.map((info) => (
              <InfosTitle key={infos.fourthpart.indexOf(info)} title={info.title} text={info.text} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Politique;
